// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

$('<p class="feed-button"><a href="/pages/events/category/Whats-on" class="cta-button">What’s on</a></p>').insertAfter('.homeFeedBox1 .feedList');
// $('<p class="feed-button"><a href="/Pages/Category/plant-wish-list" class="cta-button">View plant list</a></p>').insertAfter('.homeFeedBox2 .feedList');


// Department intro - Description
$(".footerBox ul.departmentTab").prependTo("body.departmentType .productList .listContent");


// GiftAid statement
$GitfAidtText = $('<p class="formSecondStep giftAidStatement">Just one declaration may apply to donations from the previous 4 years as well as to the forthcoming gifts that you make, until you notify us otherwise</p>');
$('.formQuestion.donationGiftAid').append( $GitfAidtText );


// Breadcrumb class name
// var breadcrumbItemText = $(".breadcrumbItem:first-of-type a").text();
// $("body:not(.homepage) form#bodyForm").attr('class', breadcrumbItemText);

$(".appealsPostBody #appealComments, .appealsPostBody #comments").remove();